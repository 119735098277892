<template>
  <div class="page-index">
    <IndexHeader :isActive="1"></IndexHeader>
    <!-- 背景图 -->
    <div class="bannerImg">
      <div class="center">
        <img
          class="background-image hidden-md-and-down"
          width="736"
          height="569"
          src="@/assets/imgs/介绍页图片.png"
          alt=""
        />
        <!-- <h1 class="slogan">锐研 · 云质析</h1> -->
        <div class="slogan">
          <img src="@/assets/imgs/name.png" alt="" />
        </div>
        <h3 class="slogan_en">互联网爬虫采集云平台</h3>
        <p class="desc">一款基于浏览器的可视化爬虫工具</p>
        <p class="desc">支持爬虫设计、分布式集群采集</p>
        <p class="desc">支持云任务管理、内置爬虫模板</p>
        <!-- <div class="go-system hidden-sm-and-up" v-if="isLogin">
        <router-link to="/start">
          <span class="into">
            进入系统
            <img src="@/assets/imgs/go.png" alt="" />
          </span>
        </router-link>
      </div> -->
        <div class="begin-use">
          <!-- <router-link to="/register">
                    <span class="register">注册</span>
                  </router-link> -->
          <router-link to="/home" v-if="isLogin">
            <el-button type="primary" class="button">开始使用</el-button>
          </router-link>
          <el-button
            type="primary"
            class="button"
            @click="handleClickGoLogin"
            v-else
          >
            开始使用
          </el-button>
        </div>
      </div>
    </div>
    <!-- icon区域 -->
    <div class="icon-box">
      <h1 class="title">主要功能</h1>
      <div class="icon-banner">
        <el-row class="bgc" :gutter="20">
          <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6">
            <div class="function-grid" @click="goSpider('#spider1')">
              <div class="fun-card">
                <div class="bg-circle">
                  <i class="iconfont icon-keshihua"></i>
                </div>
                <h4 class="function-name">可视化设计</h4>
                <p class="function-desc">专利爬虫框架，在线可视化爬虫设计</p>
              </div>
            </div>
          </el-col>
          <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6">
            <div class="function-grid" @click="goSpider('#spider2')">
              <div class="fun-card">
                <div class="bg-circle">
                  <i class="iconfont icon-shujucaiji"></i>
                </div>
                <h4 class="function-name">分布式集群采集</h4>
                <p class="function-desc">
                  分布式服务器集群、高效采集大规模数据
                </p>
              </div>
            </div>
          </el-col>
          <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6">
            <div class="function-grid" @click="goSpider('#spider3')">
              <div class="fun-card">
                <div class="bg-circle">
                  <i class="iconfont icon-compasszhinanzhen"></i>
                </div>
                <h4 class="function-name">采集策略</h4>
                <p class="function-desc">可设置灵活的定时采集策略</p>
              </div>
            </div>
          </el-col>
          <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6">
            <div class="function-grid" @click="goSpider('#spider4')">
              <div class="fun-card">
                <div class="bg-circle">
                  <i class="iconfont icon-moban"></i>
                </div>
                <h4 class="function-name">爬虫模板</h4>
                <p class="function-desc">
                  丰富的爬虫模块库，可定向采集各主流网络媒体的数据
                </p>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 主体内容区域 -->
    <div class="main">
      <div class="container" v-if="isMain">
        <div class="icon-banner">
          <el-row id="spider1" class="bgc">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content">
                <div class="main-img">
                  <img src="@/assets/imgs/index-1.png" alt="" />
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content main-text">
                <h4 class="content-title">可视化设计</h4>
                <p class="content-desc">
                  专利爬虫配置脚本语言和爬虫引擎XSpider
                </p>
                <ul class="content-item">
                  <li>
                    <i class="iconfont icon-yuandian1 dot" />
                    <span class="text">
                      爬虫设计：在线可视化爬虫设计工具，无需下载安装爬虫软件
                    </span>
                  </li>
                  <li>
                    <i class="iconfont icon-yuandian1 dot" />
                    <span class="text">
                      数据处理：基于扩展的XPath和正则语法，对采集数据进行灵活处理和格式转换
                    </span>
                  </li>
                  <li>
                    <i class="iconfont icon-yuandian1 dot" />
                    <span class="text">
                      爬虫教程：提供爬虫开发教程，轻松学习爬虫
                    </span>
                  </li>
                </ul>
              </div>
            </el-col>
          </el-row>
          <el-row id="spider2" class="bgf">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content main-text">
                <h4 class="content-title">分布式集群采集</h4>
                <p class="content-desc">分布式爬虫框架，支持分布式部署</p>
                <ul class="content-item">
                  <li>
                    <i class="iconfont icon-yuandian1 dot" />
                    <span class="text">&nbsp;分布式：应用分布式服务器集群</span>
                  </li>
                  <li>
                    <i class="iconfont icon-yuandian1 dot" />
                    <span class="text">
                      数据采集：支持大规模数据采集，日均采集量可达100万
                    </span>
                  </li>
                  <li>
                    <i class="iconfont icon-yuandian1 dot" />
                    <span class="text">
                      反爬功能：支持IP代理池，有效提升反爬能力
                    </span>
                  </li>
                </ul>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content">
                <div class="main-img">
                  <img src="@/assets/imgs/index-2.png" alt="" />
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row id="spider3" class="bgf">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content">
                <div class="main-img">
                  <img src="@/assets/imgs/index-3.png" alt="" />
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content main-text">
                <h4 class="content-title">采集策略</h4>
                <p class="content-desc">可设置灵活的定时采集策略</p>
                <ul class="content-item">
                  <li>
                    <i class="iconfont icon-yuandian1 dot" />
                    <span class="text" style="margin-left: 1px">
                      策略类型：支持设置全量采集、增量采集及缺失采集的策略类型
                    </span>
                  </li>
                  <li>
                    <i class="iconfont icon-yuandian1 dot" />
                    <span class="text">
                      执行类型：支持立即采集、定时采集及重复采集的执行类型
                    </span>
                  </li>
                  <li>
                    <i class="iconfont icon-yuandian1 dot" />
                    <span class="text">
                      关联项目：单个策略可关联多个采集项目，轻松管理单个项目采集的数据
                    </span>
                  </li>
                </ul>
              </div>
            </el-col>
          </el-row>
          <el-row id="spider4" class="bgc">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content main-text">
                <h4 class="content-title">爬虫模板</h4>
                <p class="content-desc">涵盖主流网站的数据采集模板</p>
                <ul class="content-item">
                  <li>
                    <i class="iconfont icon-yuandian1 dot" />
                    <span class="text">模板维护：爬虫模板实时更新和维护</span>
                  </li>
                  <li>
                    <i class="iconfont icon-yuandian1 dot" />
                    <span class="text">自定义：支持自定义爬虫模板</span>
                  </li>
                  <li>
                    <i class="iconfont icon-yuandian1 dot" />
                    <span class="text">爬虫市场：集成主流网站爬虫模板</span>
                  </li>
                </ul>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content">
                <div class="main-img">
                  <img src="@/assets/imgs/index-4.png" alt="" />
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="container" v-else>
        <div class="icon-banner">
          <el-row id="spider1" class="bgc">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content">
                <div class="main-img">
                  <img src="@/assets/imgs/index-1.png" alt="" />
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content main-text">
                <h4 class="content-title">可视化设计</h4>
                <p class="content-desc">
                  专利爬虫配置脚本语言和爬虫引擎XSpider
                </p>
                <ul class="content-item">
                  <li>
                    <i class="iconfont icon-yuandian1 dot" />
                    <span class="text">
                      爬虫设计：在线可视化爬虫设计工具，无需下载安装爬虫软件
                    </span>
                  </li>
                  <li>
                    <i class="iconfont icon-yuandian1 dot" />
                    <span class="text">数据处理：基于扩展的XPath和正则语法，对采集数据进行灵活处理和格式转换</span>
                  </li>
                  <li>
                    <i class="iconfont icon-yuandian1 dot" />
                    <span class="text">
                      爬虫教程：提供爬虫开发教程，轻松学习爬虫
                    </span>
                  </li>
                </ul>
              </div>
            </el-col>
          </el-row>
          <el-row id="spider2" class="bgf">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content">
                <div class="main-img">
                  <img src="@/assets/imgs/index-2.png" alt="" />
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content main-text">
                <h4 class="content-title">分布式集群采集</h4>
                <p class="content-desc">分布式爬虫框架，支持分布式部署</p>
                <ul class="content-item">
                  <li>
                    <i class="iconfont icon-yuandian1 dot" />
                    <span class="text">分布式：应用分布式服务器集群</span>
                  </li>
                  <li>
                    <i class="iconfont icon-yuandian1 dot" />
                    <span class="text">
                      AI生成编码：输入研究方向AI即可生成多组编码
                    </span>
                  </li>
                  <li>
                    <i class="iconfont icon-yuandian1 dot" />
                    <span class="text">
                      编码视图：支持列表视图及卡片试图查看编辑编码
                    </span>
                  </li>
                </ul>
              </div>
            </el-col>
          </el-row>
          <el-row id="spider3" class="bgf">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content">
                <div class="main-img">
                  <img src="@/assets/imgs/index-3.png" alt="" />
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content main-text">
                <h4 class="content-title">采集策略</h4>
                <p class="content-desc">可设置灵活的定时采集策略</p>
                <ul class="content-item">
                  <li>
                    <i class="iconfont icon-yuandian1 dot" />
                    <span class="text" style="margin-left: 1px">
                      策略类型：支持设置全量采集、增量采集及缺失采集的策略类型
                    </span>
                  </li>
                  <li>
                    <i class="iconfont icon-yuandian1 dot" />
                    <span class="text">
                      执行类型：支持立即采集、定时采集及重复采集的执行类型
                    </span>
                  </li>
                  <li>
                    <i class="iconfont icon-yuandian1 dot" />
                    <span class="text">
                      关联项目：单个策略可关联多个采集项目，轻松管理单个项目采集的数据
                    </span>
                  </li>
                </ul>
              </div>
            </el-col>
          </el-row>
          <el-row id="spider4" class="bgc">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content">
                <div class="main-img">
                  <img src="@/assets/imgs/index-4.png" alt="" />
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content main-text">
                <h4 class="content-title">爬虫模板</h4>
                <p class="content-desc">涵盖主流网站的数据采集模板</p>
                <ul class="content-item">
                  <li>
                    <i class="iconfont icon-yuandian1 dot" />
                    <span class="text">模板维护：爬虫模板实时更新和维护</span>
                  </li>
                  <li>
                    <i class="iconfont icon-yuandian1 dot" />
                    <span class="text">自定义：支持自定义爬虫模板</span>
                  </li>
                  <li>
                    <i class="iconfont icon-yuandian1 dot" />
                    <span class="text">爬虫市场：集成主流网站爬虫模板</span>
                  </li>
                </ul>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <div class="footer">
      <div class="footer-contact">
        <div class="content">
          <p class="content-title">联系我们</p>
          <p class="content-info">
            若您有问题，请添加企业客服微信，或者您也可以通过下面的邮箱地址向我们咨询：
            <br />
            marketing@monetware.com
          </p>
        </div>
        <div class="QR-code">
          <img src="@/assets/imgs/QR-code.png" />
          <p>企业客服微信</p>
        </div>
      </div>
      <IndexFooter></IndexFooter>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import IndexHeader from "@/components/layout/IndexHeader.vue";
import IndexFooter from "@/components/layout/IndexFoot.vue";
import { checkUserLoginStatus } from "@/utils/utils.js";
import { goLogin } from "@/utils/login";

const isMain = ref(true);
const screenWidth = ref("");
const isLogo = ref(true);
onMounted(() => {
  screenWidth.value = document.body.clientWidth;
  if (screenWidth.value <= 768) {
    isMain.value = false;
    isLogo.value = false;
  } else {
    isMain.value = true;
    isLogo.value = true;
  }
  window.onresize = () => {
    return (() => {
      screenWidth.value = document.body.clientWidth;
      if (screenWidth.value <= 768) {
        isMain.value = false;
        isLogo.value = false;
      } else {
        isMain.value = true;
        isLogo.value = true;
      }
    })();
  };
});

//是否登录
const isLogin = ref(false);
onMounted(() => {
  if (checkUserLoginStatus()) {
    isLogin.value = true;
  } else {
    isLogin.value = false;
  }
});

// 锚点跳转
const goSpider = (id) => {
  document.querySelector(id).scrollIntoView({
    behavior: "smooth", // 使用平滑滚动效果，可选
    block: "center",
  });
};

//前往登录
const handleClickGoLogin = () => {
  goLogin();
};
</script>

<style lang="scss" scoped>
.page-index {
  // 背景图
  .bannerImg {
    width: 100%;
    color: $theme-color;
    text-align: center;
    background: linear-gradient(
      128.07deg,
      rgba(255, 139, 181, 0.1) 6.88%,
      rgba(255, 229, 249, 0.1) 42.38%,
      rgba(246, 218, 255, 0.1) 69.05%,
      rgba(172, 120, 242, 0.1) 97.9%
    );
    // background: url("../../assets/imgs/bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    min-height: 790px;
    .center {
      z-index: 1;
      position: relative;
      max-width: 88vw;
      margin: auto;

      padding: 160px 0px;
      .background-image {
        position: absolute;
        top: 100px;
        right: 0px;
      }
      .slogan {
        height: 54px;
        line-height: 22px;
        color: rgba(56, 56, 56, 1);
        font-size: 34px;
        font-weight: bold;
        text-align: left;
        img {
          height: 50px;
        }
      }
      .slogan_en {
        min-height: 95px;
        line-height: 95px;
        color: rgba(56, 56, 56, 1);
        font-size: 36px;
        text-align: left;
        font-weight: bold;
        margin-bottom: 8px;
      }
      .desc {
        font-size: 18px;
        text-align: left;
        font-weight: 500;
        color: #848486;
        // margin-bottom: 35px;
        // margin-left: 5px;
        height: 60px;
        line-height: 25px;
        span {
          color: rgba(132, 132, 134, 1);
          margin-right: 5px;
          font-family: SourceHanSansSC-regular;
        }
      }
      .begin-use {
        text-align: left;
        .button {
          width: 230px;
          height: 52px;
          background-color: rgba(127, 68, 240, 0.1);
          color: $theme-color;
          border: none;
        }
        .button:hover {
          background-color: $theme-color;
          color: #fff;
        }
      }
    }
  }

  // ICON
  .icon-box {
    background-color: #ffffff;
    padding: 50px 40px;
    .title {
      color: rgba(16, 16, 16, 1);
      font-size: 38px;
      text-align: center;
      font-family: SourceHanSansSC-regular;
      margin: 50px 0px 50px 0px;
    }
    .icon-banner {
      max-width: 1200px;
      margin: 0 auto;
    }
    .el-col {
      display: flex;
      justify-content: center;
      .function-grid {
        display: flex;
        justify-content: center;
        cursor: pointer;
        font-size: 14px;
        text-align: center;
        padding: 40px;
        width: 80%;
        height: 300px;
        background: #ffffff;
        border-radius: 6px;
        font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
        transition-duration: 300ms;

        .fun-card {
          .bg-circle {
            width: 120px;
            height: 120px;
            text-align: center;
            line-height: 120px;
            background-color: $theme-color;
            margin: 0 auto;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
            border-radius: 50%;

            i {
              margin-top: 30px;
              width: 60px;
              height: 60px;
              font-size: 60px;
              color: #ffffff;
            }
          }
        }
        .function-name {
          font-size: 18px;
          text-align: center;
          padding: 30px 0px 20px;
          color: rgba(16, 16, 16, 1);
          font-family: SourceHanSansSC-regular;
        }
        // .function-desc {
        //   display: none;
        // }
        .function-desc {
          // display: block;
          color: #848486;
          font-size: 12px;
          line-height: 25px;
          text-align: center;
          font-family: SourceHanSansSC-regular;
        }
      }
      .function-grid:hover {
        background: #ffffff;
        box-shadow: 0px 2px 25px -10px rgba(14, 90, 47, 0.2);
        border-radius: 6px;
        transform: translate(0, -5px);
        transition-duration: 300ms;
        .function-name {
          color: $theme-color;
        }
        .function-desc {
          // display: block;
          color: rgba(56, 56, 56, 1);
          font-size: 12px;
          text-align: center;
          font-family: SourceHanSansSC-regular;
        }
      }
    }
  }

  // 主体内容区域
  .container {
    margin-top: 30px;
    display: flex;
    align-items: center;
    .icon-banner {
      max-width: 1200px;
      margin: 0 auto;
    }
    .bgc {
      background-color: #fff;
      margin-top: 30px;
      display: flex;
      align-items: center;
    }
    .bgf {
      background-color: #fff;
      margin-top: 30px;
      display: flex;
      align-items: center;
    }
    .main-img {
      padding: 20px 40px;
      img {
        display: block;
        width: 100%;
        margin: 0 auto;
      }
    }

    .main-text {
      padding: 10px 40px;
      .content-title {
        font-size: 28px;
        margin: 20px 0px 15px 0px;
        color: rgba(16, 16, 16, 1);
        text-align: left;
        font-family: SourceHanSansSC-regular;
      }
      .content-desc {
        font-size: 20px;
        font-weight: bold;
        margin: 10px 0px 20px 0px;
        color: rgba(16, 16, 16, 1);
        text-align: left;
        font-family: SourceHanSansSC-regular;
      }
      .content-item {
        list-style: disc;
        color: rgba(56, 56, 56, 1);
        line-height: 20px;
        font-size: 14px;
        text-align: left;
        font-family: SourceHanSansSC-regular;
        li {
          margin: 10px 0px;
          .dot {
            display: inline-block;
            vertical-align: middle;
            // width: 6px;
            // height: 6px;
            font-size: 8px;
            border-radius: 50%;
            color: $theme-color;
            margin-right: 8px;
          }
        }
      }
    }
  }

  // 底部
  .footer {
    max-width: 1200px;
    padding: 30px 40px;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    .footer-contact {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 400px;
      .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .content-title {
          font-size: 22px;
          font-weight: 600;
        }
        .content-info {
          margin-top: 50px;
          text-align: left;
          line-height: 25px;
          font-size: 14px;
        }
      }
      .QR-code {
        text-align: center;
        img {
          width: 150px;
          height: auto;
        }
        p {
          margin-top: 10px;
          color: #6c6c6c;
        }
      }
    }
    .footer-bottom {
      font-size: 12px;
      p {
        color: #a0a0a0;
      }
      a {
        color: $theme-color;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .go-system {
    line-height: 0;
    .into {
      margin-left: 30px;
      margin-right: 10px;
      padding: 6px 8px;
      border-radius: 4px;
      font-size: 13px;
      img {
        display: inline-block;
        width: 20px;
        vertical-align: middle;
        margin-bottom: 4px;
      }
    }
  }

  // 背景图
  .bannerImg {
    .center {
      padding: 100px 0;
      .slogan {
        text-align: left;
        font-size: 30px;
        color: $theme-color;
        font-family: SourceHanSansSC-regular;
        margin-top: 20px;
        margin-left: 10px;
        padding: 0px 15px 10px;
        img {
          height: 70px;
        }
      }
      .slogan_en {
        text-align: left;
        color: #000000;
        font-family: SourceHanSansSC-regular;
        font-size: 25px !important;
        line-height: 30px !important;
        min-height: 40px !important;
        margin-left: 10px;
        margin-top: 10px;
        padding: 0 15px 0px;
      }
      .desc {
        font-size: 14px;
        text-align: left;
        font-weight: 500;
        color: #000000;
        margin-left: 10px;
        margin-bottom: 15px;
        padding: 0 15px;
      }

      .user-name {
        margin-left: 2px;
        display: inline-block;
        padding: 0;
        line-height: 60px;
        font-size: 14px;
        color: #777;
      }
      .user-name:hover {
        color: #777;
      }
      .begin-use {
        display: flex;
        margin-left: 25px;
        margin-top: 25px;
        .login {
          background-color: $theme-color;
          border-color: $theme-color;
          color: rgba(255, 255, 255, 1);
          font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
          margin-left: 0;
          padding: 20px 50px;
        }
      }
    }
  }
  // ICON
  // ICON
  .icon-box {
    background-color: #ffffff;
    padding: 0px 20px;
    .title {
      font-size: 25px;
    }
    .el-col {
      .function-grid {
        width: 90%;
        min-height: 220px;

        margin-bottom: 20px;
        .fun-card {
          .bg-circle {
            width: 100px;
            height: 100px;

            img {
              margin-top: 25px;
              width: 50px;
              height: 50px;
            }
          }
        }
        .function-name {
          font-size: 16px;
        }
      }
    }
  }

  // 主体内容区域
  .container {
    margin-top: 30px;
    .bgc {
      background-color: #fff;
      margin-top: 30px;
    }
    .bgf {
      background-color: #fff;
      margin-top: 30px;
    }
    .main-img {
      padding: 20px 20px;
      img {
        display: block;
        width: 100%;
        margin: 0 auto;
      }
    }
    .main-text {
      padding: 10px 20px;
      .content-title {
        font-size: 22px;
      }
      .content-desc {
        font-size: 18px;
        font-weight: bold;
      }
    }
  }

  // 底部
  .footer {
    padding: 30px 10px;
    text-align: center;
    background: #fff;
    .footer-contact {
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      width: 90%;
      height: 400px;
    }
  }
}
</style>
