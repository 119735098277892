<template>
  <div class="footer">
    <!-- <div class="footer-top">
      <a href="mailto:marketing@monetware.com" target="_blank">
        <i class="iconfont">&#xe6b0;</i>
      </a>
      <a href="https://weibo.com/ringdata" target="_blank">
        <i class="iconfont">&#xe61d;</i>
      </a>
      <a href="javascript:alert('欢迎关注“锐研中国”微信公众号！')" target="_blank">
        <i class="iconfont">&#xe61c;</i>
      </a>
    </div> -->
    <!-- <div class="footer-middle">
      锐研•云访谈 提供最专业的云访谈解决方案&ensp;
      <a class="mail" href="mailto:marketing@monetware.com" target="_blank">
        mailto:marketing@monetware.com
      </a>
    </div> -->
    <div class="footer-bottom">
      Copyright © 2025 上海萌泰数据科技股份有限公司
      <a
        href="https://beian.miit.gov.cn"
        target="_blank"
        style="font-size: 12px"
      >
        &ensp;&ensp;沪ICP备09099644号-19
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.footer {
  padding: 45px 20px 55px 20px;
  text-align: center;
  // background: #eeeeee;
  .footer-top {
    a {
      display: inline-block;
      width: 45px;
      height: 45px;
      line-height: 45px;
      text-align: center;
      border-radius: 50%;
      background: #bdbdbd;
      font-size: 20px;
      color: #ffffff;
      margin-right: 20px;
    }
    i {
      font-size: 22px !important;
    }
  }
  .footer-middle {
    color: #a0a0a0;
    padding: 15px 0;
    font-size: 12px;
    .mail {
      font-size: 12px;
      color: #a0a0a0;
    }
  }
  .footer-bottom {
    color: #101010;
    font-size: 12px;
    line-height: 26px;
    a {
      color: $theme-color;
      text-decoration: none;
      font-size: 12px;
    }
  }
}
</style>
