<template>
  <div class="page-index">
    <div
      class="index-header"
      :style="{
        boxShadow: ifShowShadow
          ? '5px 5px 13px #f2f2f2,-5px -5px 13px #ffffff'
          : 'none',
        backgroundColor: ifShowShadow ? '#ffffff' : 'transparent',
      }"
    >
      <el-row>
        <el-col :span="12">
          <div class="header-left">
            <router-link to="/">
              <img src="@/assets/imgs/logo.png" />
            </router-link>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="phone-header-right">
            <a @click="showMenu">
              <i class="menu iconfont icon-list" />
            </a>
          </div>
          <div class="header-right">
            <ul>
              <router-link to="/index">
                <li>
                  <a :class="{ active: isActive == 1 }" href="#">产品</a>
                </li>
              </router-link>
              <router-link to="/pricing">
                <li>
                  <a :class="{ active: isActive == 2 }" href="#">咨询与购买</a>
                </li>
              </router-link>
              <li>
                <div class="go-system" v-if="isLogin">
                  <router-link to="/home">
                    <!-- <span>
                      进入系统
                      <img src="@/assets/imgs/system/go.png" alt="" />
                    </span> -->
                    <el-button type="primary" class="go-button">
                      进入云采集
                    </el-button>
                  </router-link>
                </div>
                <div v-else>
                  <el-button
                    type="primary"
                    class="login-button"
                    @click="handleClickGoLogin"
                  >
                    登录
                  </el-button>
                </div>
              </li>
            </ul>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="menu-box hidden-md-and-up" v-show="ifShowMenuBox">
      <ul>
        <router-link to="/">
          <li>
            <a :class="{ active: isActive == 1 }" href="#">产品</a>
          </li>
        </router-link>
        <router-link to="/pricing">
          <li>
            <a :class="{ active: isActive == 2 }" href="#">咨询与购买</a>
          </li>
        </router-link>
        <router-link to="/home">
          <li>
            <a :class="{}" href="#">进入产品</a>
          </li>
        </router-link>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, onUnmounted, defineProps, watch } from "vue";
import { goLogin } from "@/utils/login";
import { checkUserLoginStatus } from "@/utils/utils.js";
import VueCookies from "vue-cookies";

const props = defineProps({
  isActive: {
    type: Number,
  },
});

//手机版菜单弹框
const ifShowMenuBox = ref(false);
// 展开菜单
const showMenu = () => {
  ifShowMenuBox.value = !ifShowMenuBox.value;
};

//是否显示底部阴影
const ifShowShadow = ref(false);
const scroll = ref(0);
// 监听滚轮事件
const handleScroll = () => {
  scroll.value = document.documentElement.scrollTop || document.body.scrollTop;
  if (scroll.value > 0) {
    ifShowShadow.value = true;
  } else {
    ifShowShadow.value = false;
  }
};

//登录
const handleClickGoLogin = () => {
  goLogin();
};

//是否登录
const isLogin = ref(false);
onMounted(() => {
  if (checkUserLoginStatus()) {
    isLogin.value = true;
  } else {
    isLogin.value = false;
  }
  window.addEventListener("scroll", handleScroll); // 监听（绑定）滚轮滚动事件
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll); // 离开页面清除（移除）滚轮滚动事件
});
</script>

<style lang="scss" scoped>
.page-index {
  // 头部
  .index-header {
    position: fixed;
    left: 0;
    top: 0;
    height: 60px;
    width: 100%;
    overflow: hidden;
    background-color: transparent;
    z-index: 20;
    .header-left {
      // width: 20%;
      height: 60px;
      line-height: 60px;
      float: left;
      padding: 0px 10px 0px;
      img {
        display: inline-block;
        height: 35px;
        margin-left: 65px;
        vertical-align: middle;
      }
    }
    .phone-header-right {
      display: none;
    }
    .header-right {
      float: right;
      text-align: right;
      .go-system {
        img {
          position: relative;
          display: inline-block;
          width: 15px;
          vertical-align: middle;
          bottom: 1px;
        }
      }
      ul {
        width: 100%;
        height: 60px;
        line-height: 60px;
        overflow: hidden;
        margin-right: 40px;
        li {
          margin-right: 30px;
          float: left;
          cursor: pointer;
          a {
            // font-weight: bold;
          }
          a:hover {
            color: $theme-color;
          }
          .active {
            color: $theme-color;
          }
          .go-button {
            background-color: rgba(127, 68, 240, 0.1);
            color: $theme-color;
            border: none;
          }
          .go-button:hover {
            background-color: $theme-color;
            color: #ffffff;
          }
          .login-button {
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .page-index {
    // 头部
    .index-header {
      .phone-header-right {
        cursor: pointer;
        width: 35px;
        height: 60px;
        float: right;
        padding-right: 10px;
        display: inline-block;
        -webkit-tap-highlight-color: transparent;
        a {
          cursor: pointer;
          width: 35px;
          height: 60px;
          line-height: 60px;
          display: inline-block;
          -webkit-tap-highlight-color: transparent;
        }
        .menu {
          font-size: 28px;
          cursor: pointer;
          -webkit-tap-highlight-color: transparent;
          line-height: 40px;
          position: relative;
          top: 10px;
        }
      }
      .header-left {
        img {
          margin-left: 10px;
        }
      }
      .header-right {
        display: none;
      }
    }

    .menu-box {
      z-index: 20;
      position: fixed;
      top: 60px;
      left: 0;
      transform: translateX(320%);
      width: 22%;
      text-align: left;
      line-height: 50px;
      background-color: #fff;
      border-top: 1px solid #fff;
      border-bottom: 1px solid #fff;
      li {
        cursor: pointer;
        z-index: 20;
        a {
          margin-left: 10px;
          &:hover {
            color: $theme-color;
          }
          &.active {
            color: $theme-color;
          }
        }
      }
      .menu-setting {
        border-top: 1px solid #fff;
        padding-top: 15px;
      }
    }
  }
}
</style>
