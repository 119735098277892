<template>
  <el-container class="container-login">
    <!-- 头部 -->
    <el-header class="header">
      <el-row>
        <el-col :span="12" class="page-logo">
          <router-link to="/index">
            <!-- <img src="../assets/imgs/logo.png" alt="logo" class="logo-default" /> -->
          </router-link>
        </el-col>
      </el-row>
    </el-header>
    <!-- 中间 -->
    <el-main class="main_login">
      <el-row class="main_content">
        <!-- 左侧文案 -->
        <el-col
          :xs="24"
          :sm="12"
          :md="14"
          :lg="14"
          :xl="14"
          class="left hidden-xs-only"
        >
          <div class="content_box">
            <p class="title">互联网爬虫采集云平台</p>
            <p>基于web的轻量级数据采集工具</p>
            <p>可视化爬虫设计、内置丰富爬虫模版</p>
            <p>分布式集群采集、支持任务调度策略</p>
          </div>
        </el-col>
        <!-- 右侧登录表单 -->
        <el-col :xs="24" :sm="1" :md="10" :lg="10" :xl="10" class="right">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            class="login-form"
          >
            <el-form-item class="toLogin">
              <b>&nbsp;账号登录</b>
            </el-form-item>
            <!-- 登录用户名 -->
            <el-form-item prop="userName">
              <el-input
                v-model="ruleForm.userName"
                autocomplete="new-password"
                placeholder="手机号/用户名"
              ></el-input>
            </el-form-item>
            <!-- 登录密码 -->
            <el-form-item prop="password">
              <el-input
                v-model="ruleForm.password"
                autocomplete="new-password"
                type="password"
                placeholder="密码"
                @change="handleTokenSubmit('ruleForm')"
              ></el-input>
            </el-form-item>
            <!-- 记住密码 -->
            <el-form-item>
              <el-checkbox v-model="remember" class="checked">
                记住我
              </el-checkbox>
              <a class="login-form-forgot" @click="getPassword">忘记密码？</a>
              <el-button
                type="primary"
                html-type="submit"
                class="login-form-button"
                :disabled="isDisable"
                @click="handleTokenSubmit('ruleForm')"
                v-text="btnText"
              ></el-button>
              还没有账号？
              <!-- <router-link
                :to="{ name: 'Register' }"
                class="router-link-active"
              >
                立即注册
              </router-link> -->
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </el-main>

    <!-- 底部 -->
    <el-footer class="footer">
      <div class="links hidden-sm-and-down">
        <a @click="goRelate">关于我们</a>
        <a @click="goServe">服务协议</a>
        <a @click="goPrivacy">隐私政策</a>
      </div>
      <div class="copyright">
        Copyright © 2019 上海萌泰数据科技股份有限公司 ©版权所有.
        <a
          href=" http://beian.miit.gov.cn"
          target="_blank"
          style="color: #493185"
        >
          沪ICP备09099644号
        </a>
      </div>
    </el-footer>
    <!-- 嵌套子网站 -->
    <!-- <div v-show="false">
      <iframe
        ref="zhihuifeng"
        src="https://www.zhihuifeng.com/free/index.html"
      ></iframe>
      <iframe
        ref="campbell"
        src="https://www.campbellchina.com/free/index.html"
      ></iframe>
      <iframe ref="sw" src="https://www.smartsowo.com/free/sw.html"></iframe>
    </div> -->
  </el-container>
</template>

<script>
import crypto from "crypto";
import VueCookies from "vue-cookies";
import { httpPostToken, httpPost } from "@/api/httpService.js";

// import { Debounce } from "../utils/public";
export default {
  data() {
    return {
      btnText: "登录", // 登录按钮文案
      isDisable: false, //禁用登录按钮
      name: "",
      errorMsg: "",
      remember: false,
      ruleForm: {
        userName: "",
        password: "",
      },
      rules: {
        userName: [
          { required: true, message: "请输入手机号/用户名", trigger: "blur" },
        ],
        password: [
          { required: true, validator: this.psdRules, trigger: "blur" },
        ],
      },
    };
  },
  created() {},
  mounted() {
    this.checkUserIfLogin();
    if (window.localStorage.getItem("remember") == "true") {
      this.remember = true;
    } else {
      this.remember = false;
    }
    (this.ruleForm.userName = window.localStorage.getItem("loginName")),
      (this.ruleForm.password = window.localStorage.getItem("userPassword"));
  },
  methods: {
    /*判断用户是否登录*/
    checkUserIfLogin() {
      if (VueCookies.isKey("tokenWeb")) {
        let userInfo = VueCookies.get("userInfo");
        if (userInfo && !userInfo.ifLoginSpider) {
          httpPost("/user/v1/getUserPermission", null).then((res) => {
            if (res && res.code === 0) {
              userInfo.spiderPermission = res.data;
              userInfo.ifLoginSpider = true;
              VueCookies.set("userInfo", JSON.stringify(userInfo));
            }
          });
        }
        this.$router.push({
          path: "/home",
        });
      }
    },
    // 隐私政策
    goPrivacy() {
      // this.$router.push("/privacy");
      window.open("https://www.ringdata.com/privacy_policy");
    },
    goRelate() {
      window.open("http://www.monetware.com/page/introduction.html");
    },
    goServe() {
      window.open("https://www.ringdata.com/user_agreement");
    },

    /*忘记密码*/
    getPassword() {
      this.$router.push("/find/pass");
    },

    /*登录验证*/
    loginRules(rule, value, callback) {
      value = value.trim();
      if (value.length <= 0) {
        callback(new Error("请输入手机号/用户名/邮箱"));
      } else {
        callback();
      }
    },

    /* 密码验证*/
    psdRules(rule, value, callback) {
      value = value.trim();
      if (value.length <= 0) {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    },

    /* 登录事件 */
    handleTokenSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.btnText = "登录中...";
          this.isDisable = true;
          var ip = "";
          var city = "";
          if (window.localStorage.key("ip")) {
            ip = window.localStorage.getItem("ip");
            city = window.localStorage.getItem("city");
          }
          var client = "SPIDER";
          if (this.ruleForm.password.toString().length != 32) {
            const md5 = crypto.createHash("md5");
            md5.update(this.ruleForm.password);
            this.ruleForm.password = md5.digest("hex");
          }
          httpPostToken(
            "/oauth/token",
            `grant_type=password&username=${this.ruleForm.userName}&password=${this.ruleForm.password}&clientType=${client}&ip=${ip}&city=${city}`
          )
            .then((res) => {
              if (res && res.code === 0) {
                //缓存用户名密码
                if (this.remember == true) {
                  window.localStorage.setItem(
                    "loginName",
                    this.ruleForm.userName
                  );
                  window.localStorage.setItem(
                    "userPassword",
                    this.ruleForm.password
                  );
                  window.localStorage.setItem("remember", true);
                } else {
                  window.localStorage.setItem("loginName", "");
                  window.localStorage.setItem("userPassword", "");
                  window.localStorage.setItem("remember", false);
                }
                let userInfo = VueCookies.get("userInfo");
                httpPost("/user/v1/loginOrRegister", null).then((res) => {
                  if (res && res.code === 0) {
                    userInfo.spiderPermission = res.data;
                    userInfo.ifLoginSpider = true;
                    VueCookies.set("userInfo", JSON.stringify(userInfo));
                    this.$message({
                      message: "登录成功",
                      type: "success",
                    });
                    // this.freeLogin();
                    setTimeout(() => {
                      this.$router.push("/home");
                    }, 1.5 * 1000);
                  }
                });
              } else if (res) {
                this.btnText = "登录";
                this.isDisable = false;
                this.$message({
                  message: res.message,
                  type: "error",
                });
              }
            })
            .catch((error) => {
              this.btnText = "登录";
              this.isDisable = false;
            });
        }
      });
    },

    /*免登录保存Cookie*/
    freeLogin() {
      let param = {
        type: "sa",
        params: {
          tk: VueCookies.get("tokenWeb"),
          ui: VueCookies.get("userInfo"),
        },
      };
      this.$refs.zhihuifeng.contentWindow.postMessage(
        param,
        "https://www.zhihuifeng.com"
      );
      this.$refs.campbell.contentWindow.postMessage(
        param,
        "https://www.campbellchina.com"
      );
      this.$refs.sw.contentWindow.postMessage(
        param,
        "https://www.smartsowo.com"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
html body #app {
  height: 100%;
  min-width: 0 !important;
}
.container-login {
  // 头部样式
  .header {
    background-color: #f7f7f7;
    height: 60px;
    padding: 0;
    .page-logo {
      line-height: 60px;
      padding: 0px 10px 0px;
      img {
        vertical-align: middle;
        width: 220px;
        height: 55px;
      }
    }
    .btn_resgiter {
      line-height: 60px;
      height: 60px;
      padding: 13px 30px 10px;
      // 头部注册按钮
    }
  }
  // 中间样式
  .main_login {
    width: 100%;
    // background: url(../assets/imgs/login-bg.png) no-repeat;
    background-size: cover;
    position: absolute;
    top: 60px;
    bottom: 60px;
    left: 0;
    img {
      display: inline-block;
      width: 100%;
      height: 100%;
    }
    .main_content {
      height: 340px;
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      .left {
        padding: 0 0 0 80px;
        .content_box {
          text-align: left;
          color: #fff;
          margin-top: 15px;
          .title {
            font-size: 34px;
            margin-bottom: 30px;
            letter-spacing: 5px;
          }
          p {
            font-size: 18px;
          }
        }
      }
      .right {
        .login-form {
          background-color: #fff;
          padding: 20px;
          border-radius: 5px;
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
          border-top: 10px solid #760478;
          // 账户登录
          .toLogin {
            line-height: 20px;
            border-bottom: 1px solid #ccc;
            b {
              font-size: 18px;
              display: inline-block;
              width: 80px;
              height: 41px;
              border-bottom: 3px solid #760478;
            }
          }
          // 忘记密码样式
          .login-form-forgot {
            color: #760478;
            float: right;
            &:hover {
              text-decoration: none;
            }
          }
          .login-form-button {
            width: 100%;
          }
          // 登录样式

          // 立即注册按钮样式
          .router-link-active {
            color: #760478;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
  // 底部
  .footer {
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: 0;
    height: 50px;
    padding: 5px 0;
    background-color: #eaeaea;
    text-align: center;
    .links {
      text-align: center;
      a {
        color: #010101;
        font-size: 13px;
        text-decoration: none;
      }
      a ~ a {
        margin-left: 15px;
      }
    }
    .copyright {
      text-align: center;
      font-size: 11px;
      padding: 3px 0px 0px 0px;
    }
  }
}
button {
  outline: none;
}
</style>
